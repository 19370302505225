var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "inherit" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "my-2",
          attrs: { height: "50", elevation: "4", rounded: "" },
        },
        [_c("TripRequestFormMenu")],
        1
      ),
      _vm.showBanner
        ? _c(
            "v-banner",
            [
              !_vm.tripRequest.submittedUser
                ? [
                    _c(
                      "h4",
                      { staticClass: "text-h4" },
                      [
                        _vm._v(" Trip Request Details "),
                        _c("v-badge", {
                          attrs: {
                            value: !_vm.tripRequest.id,
                            color: "error",
                            content: "New",
                            overlap: "",
                            "offset-x": "-10px",
                            "offset-y": "-10px",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-label", [_vm._v("Create or edit Trip Request")]),
                  ]
                : _vm.tripRequest.status == -1 && _vm.tripRequest.submittedUser
                ? _c(
                    "div",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "amber--text text--darken-1 mb-0",
                          attrs: {
                            outlined: "",
                            icon: "mdi-alert",
                            text: "",
                            prominent: "",
                            dense: "",
                          },
                        },
                        [
                          _c(
                            "h6",
                            { staticClass: "text-h6 d-inline-block mr-5" },
                            [
                              _vm._v(
                                " Changes Requested by " +
                                  _vm._s(
                                    _vm.tripRequest.approval?.audit?.[0]
                                      ?.username
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              "Please make the required changes and resubmit this trip request."
                            ),
                          ]),
                          _c("br"),
                          _c("v-label", [
                            _vm._v(
                              _vm._s(
                                _vm.tripRequest.approval?.audit?.[0]?.comment
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "v-card",
            { class: _vm.canShowTripSideMenu ? "form nav-padding" : "form" },
            [
              _vm.canShowTripSideMenu
                ? _c(
                    "v-navigation-drawer",
                    {
                      staticClass: "rounded-l-0",
                      attrs: {
                        "mini-variant": "",
                        permanent: "",
                        absolute: "",
                        right: "",
                      },
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { nav: "" } },
                        _vm._l(_vm.sideNav, function (item) {
                          return _c(
                            "v-tooltip",
                            {
                              key: item.name,
                              attrs: { left: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-list-item",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  link: "",
                                                  ripple: false,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$emit(
                                                      "setCard",
                                                      item.name
                                                    )
                                                  },
                                                },
                                              },
                                              "v-list-item",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      content: item.num,
                                                      value: !!item.num,
                                                      color: "red",
                                                      overlap: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color:
                                                            _vm.cardContent ==
                                                            item.name
                                                              ? "primary"
                                                              : "blue-grey",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.icon)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(item.label))])]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "tripRequest"
                ? _c(
                    "v-card-text",
                    [
                      (_vm.tripRequest.status == 0 ||
                        _vm.tripRequest.status == -1) &&
                      !_vm.duplicated
                        ? _c("trip-request-stepper", {
                            attrs: {
                              tripRequest: _vm.tripRequest,
                              complete: _vm.complete,
                              tripRequestConfig: _vm.tripRequestConfig,
                              filteredCFFs: _vm.filteredCFFs,
                              e1: _vm.e1,
                              showFunding: _vm.showFunding,
                              showAdditionalInfo: _vm.showAdditionalInfo,
                            },
                            on: {
                              stepClick: function ($event) {
                                return _vm.$emit("stepClick", $event)
                              },
                              save: function ($event) {
                                return _vm.$emit("save", $event)
                              },
                              refreshTripRequest: function ($event) {
                                return _vm.$emit("refreshTripRequest", $event)
                              },
                              tripTypeSelected: function ($event) {
                                return _vm.$emit("tripTypeSelected", $event)
                              },
                              handlePreventSubmit: function ($event) {
                                return _vm.$emit("handlePreventSubmit", $event)
                              },
                              handleCFFInput: function ($event) {
                                return _vm.$emit("handleCFFInput", $event)
                              },
                            },
                          })
                        : _c("trip-request-review", {
                            attrs: {
                              tripRequest: _vm.tripRequest,
                              complete: _vm.complete,
                              tripRequestConfig: _vm.tripRequestConfig,
                              filteredCFFs: _vm.filteredCFFs,
                              e1: _vm.e1,
                              showAdditionalInfo: _vm.showAdditionalInfo,
                              duplicated: _vm.duplicated,
                            },
                            on: {
                              save: function ($event) {
                                return _vm.$emit("save", false, false, true)
                              },
                              refreshTripRequest: function ($event) {
                                return _vm.$emit("refreshTripRequest", $event)
                              },
                              tripTypeSelected: function ($event) {
                                return _vm.$emit("tripTypeSelected", $event)
                              },
                              handlePreventSubmit: function ($event) {
                                return _vm.$emit("handlePreventSubmit", $event)
                              },
                              handleCFFInput: function ($event) {
                                return _vm.$emit("handleCFFInput", $event)
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "approvals"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-approvals", {
                        attrs: { tripRequest: _vm.tripRequest },
                        on: {
                          reinitateApproval: function ($event) {
                            return _vm.$emit("reinitateApproval", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "assignments"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-assignments", {
                        ref: "assignments",
                        attrs: {
                          assignments: _vm.tripRequest.assignments,
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                        },
                        on: {
                          refreshTripRequest: function ($event) {
                            return _vm.$emit("refreshTripRequest")
                          },
                          refreshTripRequests: _vm.getTripRequests,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "comments"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-comments", {
                        ref: "comments",
                        attrs: {
                          comments: _vm.tripRequest.userComments,
                          tripRequestId: _vm.tripRequest.id,
                        },
                        on: {
                          refreshTripRequest: function ($event) {
                            return _vm.$emit("refreshTripRequest")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "emails"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-emails", {
                        ref: "emails",
                        attrs: {
                          emails: _vm.tripRequest.userEmails,
                          tripRequestId: _vm.tripRequest.id,
                          requesterId: _vm.tripRequest.submittedUser,
                        },
                        on: {
                          refreshTripRequest: function ($event) {
                            return _vm.$emit("refreshTripRequest")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "audit"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-audit", {
                        ref: "audit",
                        attrs: {
                          auditHistory: _vm.tripRequest.auditHistory,
                          approval: _vm.tripRequest.approval,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-toolbar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cardContent == "tripRequest",
                      expression: "cardContent == 'tripRequest'",
                    },
                  ],
                  staticClass: "footer-border-top rounded-r-0",
                  attrs: { flat: "" },
                },
                [
                  !_vm.isWrapping
                    ? _c(
                        "div",
                        {
                          ref: "levelContainer",
                          staticClass: "level-container",
                        },
                        [
                          _vm.tripRequest.status == 1 &&
                          _vm.tripRequest.permissions.canApprove &&
                          !_vm.tripRequest.approval.approved
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2 action-btn",
                                    attrs: { color: "error" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("denyRequest")
                                      },
                                    },
                                  },
                                  [_vm._v("Deny Request")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2 action-btn",
                                    attrs: { color: "warning" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("requestChanges")
                                      },
                                    },
                                  },
                                  [_vm._v("Request Changes")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2 action-btn",
                                    attrs: {
                                      color: "success",
                                      loading: _vm.approving,
                                      disabled:
                                        _vm.approving ||
                                        !_vm.canApproveCurrentTrip,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("approve")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Approve " +
                                        _vm._s(
                                          _vm.getApprovalName(_vm.tripRequest)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _c("v-spacer"),
                          _vm.tripRequestConfig.display.sendQuotes &&
                          (_vm.tripRequest.vehicleType ==
                            _vm.VEHICLE_TYPES.APPROVED_CHARTER ||
                            _vm.tripRequest.vehicleType ==
                              _vm.VEHICLE_TYPES.CONTRACTOR) &&
                          !_vm.tripRequest.additionalTransportationId
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2 action-btn",
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("requestQuote")
                                      },
                                    },
                                  },
                                  [_vm._v(" Request For Quote ")]
                                ),
                              ]
                            : _vm._e(),
                          _vm.showTripEstimator
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2 action-btn",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("openTripEstimator")
                                    },
                                  },
                                },
                                [_vm._v(" Trip Estimator ")]
                              )
                            : _vm._e(),
                          _vm.showDownloadPermissionSlip
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2 action-btn",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("permissionSlip")
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("mdi-download")]),
                                  _vm._v(" Permission Slip "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.tripRequest.status == 1
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    top: "",
                                    disabled:
                                      !(
                                        !_vm.canReschedule ||
                                        (!_vm.me.is.superAdmin &&
                                          !_vm.me.is.transportationAdmin &&
                                          !_vm.me.is.limitedAdmin &&
                                          _vm.cannotReschedule)
                                      ) &&
                                      !_vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b({}, "div", attrs, false),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mx-2 action-btn",
                                                    attrs: {
                                                      color: "primary",
                                                      disabled:
                                                        !_vm.canReschedule ||
                                                        (!_vm.me.is
                                                          .superAdmin &&
                                                          !_vm.me.is
                                                            .transportationAdmin &&
                                                          !_vm.me.is
                                                            .limitedAdmin &&
                                                          _vm.cannotReschedule) ||
                                                        _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$emit(
                                                          "cancel"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Cancel Trip ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3051317075
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cancelOrRescheduleMessage ||
                                          (_vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending
                                            ? _vm.cannotCancelOrRescheduleMessage
                                            : "If you would like to cancel this trip please contact your Transportation Director")
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.allowTripReschedule
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    top: "",
                                    disabled:
                                      !(
                                        !_vm.allowTripReschedule ||
                                        (!_vm.me.is.superAdmin &&
                                          !_vm.me.is.transportationAdmin &&
                                          !_vm.me.is.limitedAdmin &&
                                          _vm.cannotReschedule)
                                      ) &&
                                      !_vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b({}, "div", attrs, false),
                                                on
                                              ),
                                              [
                                                _vm.allowTripReschedule
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mx-2 action-btn",
                                                        attrs: {
                                                          color: "primary",
                                                          disabled:
                                                            !_vm.allowTripReschedule ||
                                                            (!_vm.me.is
                                                              .superAdmin &&
                                                              !_vm.me.is
                                                                .transportationAdmin &&
                                                              !_vm.me.is
                                                                .limitedAdmin &&
                                                              _vm.cannotReschedule) ||
                                                            _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "reschedule"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Reschedule ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1408412813
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.cancelOrRescheduleMessage ||
                                          (_vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending
                                            ? _vm.cannotCancelOrRescheduleMessage
                                            : "If you would like to reschedule this trip please contact your Transportation Director")
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.allowTripDuplicate && _vm.canCreateDuplicate
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2 action-btn",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("createDuplicate")
                                    },
                                  },
                                },
                                [_vm._v(" Create Duplicate ")]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-menu",
                            {
                              staticClass: "bottom-nav",
                              attrs: { "offset-y": "", top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _vm.tripRequest.status != 0 &&
                                      !_vm.duplicated
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.cardContent ==
                                                        "tripRequest",
                                                      expression:
                                                        "cardContent == 'tripRequest'",
                                                    },
                                                  ],
                                                  staticClass: "mt-2 mx-2",
                                                  attrs: { color: "primary" },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Actions ")]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _vm.tripRequest.status == 1 &&
                                  _vm.tripRequest.permissions.canApprove &&
                                  !_vm.tripRequest.approval.approved
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("denyRequest")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "error" } },
                                                [_vm._v("mdi-close-circle")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Deny Request"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.tripRequest.status == 1 &&
                                  _vm.tripRequest.permissions.canApprove &&
                                  !_vm.tripRequest.approval.approved
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("requestChanges")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "warning" } },
                                                [_vm._v("mdi-pencil-circle")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Request Changes"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.tripRequest.status == 1 &&
                                  _vm.tripRequest.permissions.canApprove &&
                                  !_vm.tripRequest.approval.approved
                                    ? _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            loading: _vm.approving,
                                            disabled:
                                              _vm.approving ||
                                              !_vm.canApproveCurrentTrip,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("approve")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "success" } },
                                                [_vm._v("mdi-check-circle")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              " Approve " +
                                                _vm._s(
                                                  _vm.getApprovalName(
                                                    _vm.tripRequest
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-divider"),
                                  _vm.tripRequestConfig.display.sendQuotes &&
                                  (_vm.tripRequest.vehicleType ==
                                    _vm.VEHICLE_TYPES.APPROVED_CHARTER ||
                                    _vm.tripRequest.vehicleType ==
                                      _vm.VEHICLE_TYPES.CONTRACTOR) &&
                                  !_vm.tripRequest.additionalTransportationId
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("requestQuote")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [
                                                  _vm._v(
                                                    "mdi-file-document-outline"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Request For Quote"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showTripEstimator
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "openTripEstimator"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("mdi-map-marker")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Trip Estimator"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showDownloadPermissionSlip
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("permissionSlip")
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("mdi-download")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Permission Slip"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-divider"),
                                  _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.canReschedule ||
                                                                (!_vm.me.is
                                                                  .superAdmin &&
                                                                  !_vm.me.is
                                                                    .transportationAdmin &&
                                                                  !_vm.me.is
                                                                    .limitedAdmin &&
                                                                  _vm.cannotReschedule) ||
                                                                _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "cancel"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-icon",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-cancel"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  "Cancel Trip"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3472928131
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.cannotCancelOrRescheduleMessage
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                !_vm.canReschedule ||
                                                                (!_vm.me.is
                                                                  .superAdmin &&
                                                                  !_vm.me.is
                                                                    .transportationAdmin &&
                                                                  !_vm.me.is
                                                                    .limitedAdmin &&
                                                                  _vm.cannotReschedule) ||
                                                                _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.$emit(
                                                                  "reschedule"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-icon",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-calendar-edit"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  "Reschedule"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1274396729
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.cannotCancelOrRescheduleMessage
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.allowTripDuplicate &&
                                  _vm.canCreateDuplicate
                                    ? _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "createDuplicate"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [
                                                  _vm._v(
                                                    "mdi-content-duplicate"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v("Create Duplicate"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c("v-spacer"),
                  _vm.tripRequest.status == 1 &&
                  (_vm.tripRequest.permissions.canEdit ||
                    _vm.tripRequest.permissions.canEditSiteAdmin ||
                    _vm.tripRequest.permissions.canEditApprover ||
                    _vm.tripRequest.permissions.canEditPreApproval ||
                    _vm.tripRequest.permissions.canEditMidApproval ||
                    _vm.tripRequest.permissions.canEditApproved)
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            color: "success",
                            disabled: _vm.preventSubmit,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("save", true)
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      )
                    : _vm._e(),
                  _vm.tripRequest.status != 1 && !_vm.duplicated
                    ? [
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.e1 > 1,
                                expression: "e1 > 1",
                              },
                            ],
                            staticClass: "nav-buttons",
                            attrs: {
                              color: "primary",
                              disabled: _vm.preventSubmit,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("prev")
                              },
                            },
                          },
                          [_vm._v(" Prev ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showNext,
                                expression: "showNext",
                              },
                            ],
                            staticClass: "nav-buttons",
                            attrs: {
                              color: "primary",
                              loading: _vm.isProcessingNextStep,
                              disabled: _vm.preventSubmit,
                              "data-cy": "trip-request-next-btn",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("next")
                              },
                            },
                          },
                          [_vm._v(" Next ")]
                        ),
                      ]
                    : _vm._e(),
                  _vm.showSubmit
                    ? _c(
                        "div",
                        [
                          _vm.tripRequestConfig.display.sendQuotes &&
                          _vm.tripRequestConfig.display.contractors &&
                          (_vm.tripRequest.vehicleType ==
                            _vm.VEHICLE_TYPES.APPROVED_CHARTER ||
                            _vm.tripRequest.vehicleType ==
                              _vm.VEHICLE_TYPES.CO) &&
                          !_vm.tripRequest.additionalTransportationId
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.saving,
                                    disabled: _vm.preventSubmit || _vm.saving,
                                    "data-cy": "trip-request-save-as-draft-btn",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("save", false, true)
                                    },
                                  },
                                },
                                [_vm._v(" Save as Draft ")]
                              )
                            : _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "nav-buttons",
                                                    attrs: {
                                                      color: "primary",
                                                      disabled:
                                                        _vm.preventSubmit ||
                                                        _vm.submitting,
                                                      "data-cy":
                                                        "trip-request-submit-btn",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$emit(
                                                          "submit"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" Submit ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3190078217
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.tripRequestConfig.messages.final
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }