<template>
  <div class="pa-2">
    <section class="mb-4">
      <div v-if="isTripApproved" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-checkbox-marked-circle </v-icon>
        <h2>This trip has been approved.</h2>
      </div>
      <div v-else class="d-flex">
        <v-icon class="mr-2" color="blue"> mdi-alert-circle </v-icon>
        <h2>This trip requires approval.</h2>
      </div>
    </section>

    <section>
      <TripRequestApprovalTable :tripRequest="tripRequest" @showApprovalConfimation="showApprovalConfimation" />
    </section>

    <v-dialog v-model="showApprovalConfimationDialog" max-width="500px">
      <v-card>
        <v-card-title> Are you sure? </v-card-title>
        <v-card-text>
          Reinitiating the approval from this level would require all levels proceeding it to also approve.
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn color="error" @click="closeModal()">Cancel</v-btn>
          <v-btn color="success" @click="renitiateApproval()">Reinitiate Approval</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toLocaleDateTime } from '@/util';
import TripRequestApprovalTable from '@/components/TripRequest/ApprovalTable.vue';

export default {
  name: 'TripRequestApprovals',
  components: { TripRequestApprovalTable },
  props: {
    tripRequest: {
      approval: {
        requiredApprovalLevels: Array,
      },
    },
  },
  data() {
    return {
      reinitateLevel: undefined,
      showApprovalConfimationDialog: false,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    approvalLevelsDisplay() {
      if (this.tripRequest.approval.requiredApprovalLevels) return this.tripRequest.approval.requiredApprovalLevels;
      return this.tripRequest.approval.deniedCanceledApprovalLevels;
    },
    isTripApproved() {
      if (this.tripRequest.approval.fullyApproved) return true;

      return this.tripRequest.approved && this.tripRequest.approval.approved;
    },
  },
  methods: {
    toLocaleDateTime,
    getLevelName(level) {
      const { fundingSourceName, name } = level;

      if (fundingSourceName) return `${name} - ${fundingSourceName}`;

      return name;
    },
    showApprovalConfimation({ id }) {
      this.reinitateLevel = id;
      this.showApprovalConfimationDialog = true;
    },

    closeModal() {
      this.reinitateLevel = undefined;
      this.showApprovalConfimationDialog = false;
    },

    getStatus(level) {
      if (!this.tripRequest.approval.history.length) return 'Not Approved';

      const record = this.tripRequest.approval.history.find((item) => item.approvalLevelId === level.id);

      // if (record) {
      //   if (record.approved === -1) return 'Denied';
      //   return record.approved ? 'Approved' : 'Not Approved';
      // }
      // return 'Not Approved';

      const isApproved = level.status === 'approved';
      return isApproved ? 'Approved' : 'Not Approved';
    },

    getActor(levelIndex) {
      const historyLength = this.tripRequest.approval.history.length;

      return this.tripRequest.approval.history[historyLength - levelIndex - 1]?.user;
    },

    getApprover(level) {
      const record = this.tripRequest.approval.requiredApprovalLevels.find((item) => item.id === level.approvalLevelId);
      return record.name;
    },

    renitiateApproval() {
      this.$emit('reinitateApproval', { tripRequestId: this.tripRequest.id, approvalLevelId: this.reinitateLevel });
      this.closeModal();
    },
  },
};
</script>
<style scoped>
.tooltip-trigger {
  cursor: pointer;
}

.v-application .v-tooltip__content {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding-top: 20px;
}

.approvers-width {
  width: 450px;
}
</style>
