var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-2" },
    [
      _c("section", { staticClass: "mb-4" }, [
        _vm.isTripApproved
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "green" } },
                  [_vm._v(" mdi-checkbox-marked-circle ")]
                ),
                _c("h2", [_vm._v("This trip has been approved.")]),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "blue" } },
                  [_vm._v(" mdi-alert-circle ")]
                ),
                _c("h2", [_vm._v("This trip requires approval.")]),
              ],
              1
            ),
      ]),
      _c(
        "section",
        [
          _c("TripRequestApprovalTable", {
            attrs: { tripRequest: _vm.tripRequest },
            on: { showApprovalConfimation: _vm.showApprovalConfimation },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.showApprovalConfimationDialog,
            callback: function ($$v) {
              _vm.showApprovalConfimationDialog = $$v
            },
            expression: "showApprovalConfimationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Are you sure? ")]),
              _c("v-card-text", [
                _vm._v(
                  " Reinitiating the approval from this level would require all levels proceeding it to also approve. "
                ),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "pb-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.renitiateApproval()
                        },
                      },
                    },
                    [_vm._v("Reinitiate Approval")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }