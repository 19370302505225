var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1000px",
        "no-click-animation": "",
        persistent: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Request For Quote")]),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.send } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.addTrans,
                              "item-text": "name",
                              label: _vm.title,
                              multiple: "",
                              "return-object": "",
                              outlined: "",
                              chips: "",
                              clearable: "",
                              "deletable-chips": "",
                            },
                            model: {
                              value: _vm.$v.form.sendTo.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.sendTo, "$model", $$v)
                              },
                              expression: "$v.form.sendTo.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Reply To Email",
                              value: _vm.form.replyTo,
                              required: "",
                              "error-descriptions": _vm.handleErrors(
                                _vm.$v.form.replyTo
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.replyTo.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.replyTo.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.replyTo, "$model", $$v)
                              },
                              expression: "$v.form.replyTo.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Subject",
                              value: _vm.form.subject,
                              required: "",
                              "error-descriptions": _vm.handleErrors(
                                _vm.$v.form.subject
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.subject.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.subject.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.subject, "$model", $$v)
                              },
                              expression: "$v.form.subject.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              outlined: "",
                              label: "Message",
                              value: _vm.form.content,
                              required: "",
                              "error-descriptions": _vm.handleErrors(
                                _vm.$v.form.content
                              ),
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.$v.form.content.$touch()
                              },
                            },
                            model: {
                              value: _vm.$v.form.content.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.form.content, "$model", $$v)
                              },
                              expression: "$v.form.content.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.send()
                    },
                  },
                },
                [_vm._v(" Send ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }