<template>
  <v-container fluid>
    <Spinner v-if="loading" />
    <v-simple-table v-if="approvalLevels.length && !loading">
      <thead>
        <tr>
          <th class="text-left">Date</th>
          <th class="text-left">Level</th>
          <th class="text-left">Name</th>
          <th class="text-left">Status</th>
          <th class="text-left">Approver</th>
          <th class="text-left" v-if="isAdmin">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in approvalLevels" :key="index">
          <td>
            <span v-if="item.status === 'approved'">{{ getApprovedDate(item.approvalLevelId) }}</span>
          </td>
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ toUcWords(item.status) }}</td>
          <td>
            <span>
              <template v-if="item.fundingSourceName">{{ item.approverName }}</template>
              <template v-else-if="item.status == 'pending'">{{ getPrimaryApprover(item.approvalLevelId) }}</template>
              <template v-else-if="item.status == 'approved'">{{ getApprovedName(item.approvalLevelId) }}</template>
              <template v-else>{{ getApproverInAudit(item.approvalLevelId) }}</template>
              <v-tooltip v-if="hasOtherApprovers(item) && item.status === 'pending'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="font-italic text--disabled">
                    {{ getOtherApproverLabel(item) }}
                  </span>
                </template>
                <span>{{ getOtherApproverNames(item) }}</span>
              </v-tooltip>
            </span>
          </td>
          <td v-if="isAdmin">
            <v-btn
              v-if="showReinitiateButton({ item, isFirstApprover: index === 0 })"
              color="primary"
              @click="handleReinitiateApproval(item.approvalLevelId)"
            >
              Reinitiate Approval
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-alert v-if="!approvalLevels.length && !loading" type="info">
      This trip has no approvers and is auto approved.
    </v-alert>
  </v-container>
</template>

<script>
import { toUcWords, toLocaleDateTime } from '@/util';
import { mapGetters } from 'vuex';
import Spinner from '@/components/shared/Spinner.vue';
import approverApi from '@/apis/approver';
export default {
  name: 'TripRequestApprovalTable',
  components: { Spinner },
  props: {
    tripRequest: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      loading: false,
      approvers: [],
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', ['currentTripRequest']),
    isAdmin() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
    },
    approvalLevels() {
      return this.currentTripRequest.approval.requiredApprovalLevels;
    },
  },
  methods: {
    toUcWords,
    toLocaleDateTime,
    getOtherApproverNames(item) {
      const otherApprovers = this.getOtherApprovers(item);

      return otherApprovers.map((approver) => approver?.userDisplayName).join(', ');
    },
    hasOtherApprovers(item) {
      const otherApprovers = this.getOtherApprovers(item);

      return otherApprovers.length > 0;
    },
    getAllApprovers(item) {
      return [...item.primaryApprovers, ...item.secondaryApprovers];
    },
    getOtherApprovers(item) {
      const primaryApprover = this.getPrimaryApprover(item.approvalLevelId);

      return this.getAllApprovers(item).filter((approver) => approver?.userDisplayName !== primaryApprover);
    },
    showReinitiateButton({ item, isFirstApprover }) {
      if (item.status === 'denied' && isFirstApprover) return true;

      return item.status == 'approved';
    },
    handleReinitiateApproval(approvalLevelId) {
      this.$emit('showApprovalConfimation', { id: approvalLevelId });
    },
    async setApprovers() {
      try {
        this.loading = true;

        let url = `ttid=${this.currentTripRequest.tripTypeId}&lid=${this.currentTripRequest.locationId}`;
        const fs = this.tripRequest.approval.requiredApprovalLevels.find((item) => item.fundingSourceName !== null);
        if (fs) url += `&includeFs=1&fsUserId=${fs.userId}`;

        this.approvers = await approverApi.getApprovers(url);
      } catch (e) {
        this.$myalert.error('A problem occurred while fetching approvers.', true);
      } finally {
        this.loading = false;
      }
    },
    getPrimaryApprover(levelId) {
      const approver = this.approvers.find((approver) => approver.approvalLevelId === levelId && approver.isPrimary);
      if (!approver) {
        const level = this.approvalLevels?.find((lev) => lev?.approvalLevelId === levelId);
        if (level?.fundingSourceName) return level?.approverName ?? 'Funding Source Approver';

        return 'This level has no primary approver';
      }
      const primary = approver.primaryApprovers?.[0]?.userDisplayName;
      return primary ? primary : approver?.userDisplayName ?? '';
    },
    getApprovedDate(levelId) {
      const approver = this.tripRequest?.approval?.history?.find((user) => user.approvalLevelId === levelId);
      return toLocaleDateTime(approver?.details?.approvedAt) ?? '';
    },
    getApprovedName(levelId) {
      const approver = this.tripRequest.approval.history.find((user) => user.approvalLevelId === levelId);
      return approver?.approverName ?? approver?.user ?? '';
    },
    getApproverInAudit(levelId) {
      const approver = this.tripRequest.approval.audit.find((user) => user.approvalLevelId === levelId);
      return approver?.username ?? '';
    },

    getOtherApproverLabel(item) {
      const otherCount = this.getOtherApprovers(item)?.length;
      if (otherCount < 2) return '+1 other';
      return `+${otherCount} others`;
    },
  },
  async mounted() {
    await this.setApprovers();
  },
};
</script>
