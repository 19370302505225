var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "form-wrapper" },
    [
      _vm.loading
        ? _c("v-col", [_c("Spinner")], 1)
        : _c(
            "v-col",
            { staticClass: "form-content", attrs: { cols: "12", md: "12" } },
            [
              !_vm.duplicated
                ? _c(
                    "div",
                    { staticClass: "title pl-0" },
                    [
                      _vm.tripRequest.status == -2 ||
                      _vm.tripRequest.status == -3
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "red lighten-2",
                                outlined: "",
                                dense: "",
                                text: "",
                                type: "warning",
                                prominent: "",
                              },
                            },
                            [
                              _c("h2", [
                                _vm._v(
                                  "This trip has been " +
                                    _vm._s(
                                      _vm.tripRequest.status == -2
                                        ? "denied"
                                        : "canceled"
                                    )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "h6",
                        { staticClass: "text-h5 font-weight-bold inline" },
                        [_vm._v("Trip Request #" + _vm._s(_vm.tripRequest.id))]
                      ),
                      _c(
                        "div",
                        { staticClass: "text-subtitle-2 inline ml-2" },
                        [_vm._v(_vm._s(_vm.submitted))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "right mobile-hide",
                          attrs: { color: "primary" },
                          on: { click: _vm.print },
                        },
                        [_c("v-icon", [_vm._v("mdi-printer")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tripRequest.status == 1 &&
              !_vm.tripRequest.approval.approved &&
              _vm.tripRequest.recurrence.length > 0
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            outlined: "",
                            type: "warning",
                            icon: "mdi-autorenew",
                            text: "",
                          },
                        },
                        [
                          _vm._v(
                            " This trip is recurring. Once approved a duplicate trip will be created for each of the selected dates. "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "text-h6 section-heading" }, [
                    _vm._v("General"),
                  ]),
                  _c("step-general", {
                    ref: "stepGeneral",
                    attrs: {
                      tripRequest: _vm.tripRequest,
                      tripRequestConfig: _vm.tripRequestConfig,
                      customFormFields: _vm.filteredCFFs[0],
                      readonly: _vm.readonly,
                    },
                    on: {
                      tripTypeSelected: function ($event) {
                        return _vm.tripTypeSelected($event)
                      },
                      handleCFFInput: function ($event) {
                        return _vm.handleCFFInput($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "text-h6 section-heading" }, [
                    _vm._v("Leave / Return"),
                  ]),
                  _c("step-leave-return", {
                    attrs: {
                      tripRequest: _vm.tripRequest,
                      tripRequestConfig: _vm.tripRequestConfig,
                      customFormFields: _vm.filteredCFFs[1],
                      readonly: _vm.readonly,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.handleCFFInput($event)
                      },
                      preventSubmit: function ($event) {
                        return _vm.handlePreventSubmit($event)
                      },
                      refresh: _vm.refreshTripRequest,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "text-h6 section-heading" }, [
                    _vm._v("Destination(s)"),
                  ]),
                  _c("step-destination", {
                    staticClass: "section",
                    attrs: {
                      customFormFields: _vm.filteredCFFs[2],
                      readonly: _vm.readonly,
                      tripRequest: _vm.tripRequest,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.handleCFFInput($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "text-h6 section-heading" }, [
                    _vm._v("Attendees"),
                  ]),
                  _c("step-attendees", {
                    staticClass: "section",
                    attrs: {
                      tripRequest: _vm.tripRequest,
                      tripRequestConfig: _vm.tripRequestConfig,
                      customFormFields: _vm.filteredCFFs[3],
                      readonly: _vm.readonly,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.handleCFFInput($event)
                      },
                      preventSubmit: function ($event) {
                        return _vm.handlePreventSubmit($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "text-h6 section-heading" }, [
                    _vm._v("Transportation"),
                  ]),
                  _c("step-transportation", {
                    staticClass: "section",
                    attrs: {
                      tripRequest: _vm.tripRequest,
                      tripRequestConfig: _vm.tripRequestConfig,
                      customFormFields: _vm.filteredCFFs[4],
                      readonly: _vm.readonly,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.handleCFFInput($event)
                      },
                      preventSubmit: function ($event) {
                        return _vm.handlePreventSubmit($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "text-h6 section-heading" }, [
                    _vm._v("Funding"),
                  ]),
                  _c("step-funding-sources", {
                    staticClass: "section",
                    attrs: {
                      tripRequest: _vm.tripRequest,
                      tripRequestConfig: _vm.tripRequestConfig,
                      customFormFields: _vm.filteredCFFs[5],
                      readonly: _vm.readonly,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.handleCFFInput($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.showAdditionalInfo
                ? _c(
                    "div",
                    { staticClass: "section" },
                    [
                      _c("div", { staticClass: "text-h6 section-heading" }, [
                        _vm._v("Additional Info"),
                      ]),
                      _c("step-additional-info", {
                        staticClass: "section",
                        attrs: {
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                          customFormFields: _vm.filteredCFFs[6],
                          readonly: _vm.readonly,
                        },
                        on: {
                          handleCFFInput: function ($event) {
                            return _vm.handleCFFInput($event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "text-h6 section-heading" }, [
                    _vm._v("Supporting Documents"),
                  ]),
                  _c("step-supporting-documents", {
                    staticClass: "section",
                    attrs: {
                      tripRequest: _vm.tripRequest,
                      customFormFields: _vm.filteredCFFs[7],
                      readonly:
                        _vm.readonly &&
                        _vm.me.id !== _vm.tripRequest.submittedUser,
                    },
                    on: {
                      handleCFFInput: function ($event) {
                        return _vm.handleCFFInput($event)
                      },
                      refresh: _vm.refreshTripRequest,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }