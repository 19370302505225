var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", "no-click-animation": "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.deny } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: "Add Comments",
                              rules: [(v) => !!v || "Comments are required"],
                            },
                            model: {
                              value: _vm.tripRequest.approval.comments,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tripRequest.approval,
                                  "comments",
                                  $$v
                                )
                              },
                              expression: "tripRequest.approval.comments",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.saving,
                    disabled: _vm.saving || !_vm.tripRequest.approval.comments,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deny()
                    },
                  },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }