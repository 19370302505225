var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      class: !_vm.isMobile ? "px-8" : "px-4 py-0",
      style: !_vm.isMobile ? "height: 100%" : "",
      attrs: { fluid: "" },
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("v-progress-circular", {
                staticClass: "progress",
                attrs: { size: 50, color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : [
            !_vm.isMobile
              ? _c("request-detail-desktop", {
                  attrs: {
                    tripRequest: _vm.tripRequest,
                    tripRequestConfig: _vm.tripRequestConfig,
                    complete: _vm.complete,
                    filteredCFFs: _vm.filteredCFFs,
                    e1: _vm.e1,
                    cardContent: _vm.cardContent,
                    duplicated: _vm.duplicated,
                    showFunding: _vm.showFunding,
                    showAdditionalInfo: _vm.showAdditionalInfo,
                    showTripEstimator: !!_vm.showTripEstimator,
                    showDownloadPermissionSlip: _vm.showDownloadPermissionSlip,
                    preventSubmit: _vm.preventSubmit,
                    submitting: _vm.submitting,
                    showNext: _vm.showNext,
                    showSubmit: _vm.showSubmit,
                    levelTooltips: _vm.levelTooltips,
                    sideNav: _vm.sideNav,
                    approving: _vm.approving,
                    canReschedule: _vm.canReschedule,
                    cannotReschedule: _vm.cannotReschedule,
                    cannotCancelOrRescheduleDueToInvoiceStatusNotPending:
                      _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                    cancelOrRescheduleMessage: _vm.cancelOrRescheduleMessage,
                    canCreateDuplicate: _vm.canCreateDuplicate,
                    levelColors: _vm.levelColors,
                    isProcessingNextStep: _vm.isProcessingNextStep,
                  },
                  on: {
                    save: _vm.save,
                    next: _vm.next,
                    prev: _vm.prev,
                    refreshTripRequest: _vm.refreshTripRequest,
                    stepClick: _vm.stepClick,
                    setCard: _vm.setCard,
                    openTripEstimator: _vm.openTripEstimator,
                    permissionSlip: _vm.permissionSlip,
                    cancel: _vm.cancel,
                    reschedule: _vm.reschedule,
                    createDuplicate: _vm.createDuplicate,
                    submit: _vm.submit,
                    denyRequest: _vm.denyRequest,
                    requestChanges: _vm.requestChanges,
                    approve: _vm.approve,
                    requestQuote: _vm.requestQuote,
                    tripTypeSelected: _vm.tripTypeSelected,
                    handlePreventSubmit: _vm.handlePreventSubmit,
                    handleCFFInput: _vm.handleCFFInput,
                    reinitateApproval: _vm.reinitateApproval,
                  },
                })
              : _c("request-detail-mobile", {
                  attrs: {
                    tripRequest: _vm.tripRequest,
                    tripRequestConfig: _vm.tripRequestConfig,
                    complete: _vm.complete,
                    filteredCFFs: _vm.filteredCFFs,
                    e1: _vm.e1,
                    cardContent: _vm.cardContent,
                    duplicated: _vm.duplicated,
                    showFunding: _vm.showFunding,
                    showAdditionalInfo: _vm.showAdditionalInfo,
                    showTripEstimator: !!_vm.showTripEstimator,
                    showDownloadPermissionSlip: _vm.showDownloadPermissionSlip,
                    preventSubmit: _vm.preventSubmit,
                    submitting: _vm.submitting,
                    showNext: _vm.showNext,
                    showSubmit: _vm.showSubmit,
                    levelTooltips: _vm.levelTooltips,
                    sideNav: _vm.sideNav,
                    approving: _vm.approving,
                    canReschedule: _vm.canReschedule,
                    cannotReschedule: _vm.cannotReschedule,
                    cannotCancelOrRescheduleDueToInvoiceStatusNotPending:
                      _vm.cannotCancelOrRescheduleDueToInvoiceStatusNotPending,
                    cancelOrRescheduleMessage: _vm.cancelOrRescheduleMessage,
                    canCreateDuplicate: _vm.canCreateDuplicate,
                    levelColors: _vm.levelColors,
                    isProcessingNextStep: _vm.isProcessingNextStep,
                  },
                  on: {
                    save: _vm.save,
                    next: _vm.next,
                    prev: _vm.prev,
                    refreshTripRequest: _vm.refreshTripRequest,
                    stepClick: _vm.stepClick,
                    setCard: _vm.setCard,
                    openTripEstimator: _vm.openTripEstimator,
                    permissionSlip: _vm.permissionSlip,
                    cancel: _vm.cancel,
                    reschedule: _vm.reschedule,
                    createDuplicate: _vm.createDuplicate,
                    submit: _vm.submit,
                    denyRequest: _vm.denyRequest,
                    requestChanges: _vm.requestChanges,
                    approve: _vm.approve,
                    requestQuote: _vm.requestQuote,
                    tripTypeSelected: _vm.tripTypeSelected,
                    handlePreventSubmit: _vm.handlePreventSubmit,
                    handleCFFInput: _vm.handleCFFInput,
                  },
                }),
          ],
      _c("step-trip-acceptance", {
        ref: "acceptance",
        attrs: {
          tripRequest: _vm.tripRequest,
          tripRequestConfig: _vm.tripRequestConfig,
        },
        on: {
          refreshTripRequest: _vm.refreshTripRequest,
          submit: _vm.handleSubmit,
        },
      }),
      _c("cancel-trip", {
        ref: "cancel",
        attrs: { tripRequest: _vm.tripRequest },
        on: { refreshTripRequests: _vm.getTripRequests },
      }),
      _c("trip-estimator", {
        ref: "tripEstimator",
        attrs: { tripRequest: _vm.tripRequest },
      }),
      _c("reschedule-trip", {
        ref: "reschedule",
        attrs: { tripRequest: _vm.tripRequest },
        on: {
          refreshTripRequest: _vm.refreshTripRequest,
          refreshTripRequests: _vm.getTripRequests,
        },
      }),
      _c("request-quote", {
        ref: "quote",
        attrs: {
          tripRequest: _vm.tripRequest,
          type: _vm.tripRequest.vehicleTypeId,
          defaultMessage: _vm.tripRequestConfig.messages.requestQuote,
        },
      }),
      _c("deny-modal", {
        ref: "deny",
        attrs: { tripRequest: _vm.tripRequest },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }