var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "request-detail-mobile" },
    [
      _vm.showBanner
        ? _c(
            "v-banner",
            [
              _vm.showBannerAlert
                ? _c(
                    "v-alert",
                    {
                      staticClass: "amber--text text--darken-1 mb-0",
                      attrs: {
                        outlined: "",
                        icon: "mdi-alert",
                        text: "",
                        prominent: "",
                        dense: "",
                      },
                    },
                    [
                      _c("h6", { staticClass: "text-h6 d-inline-block mr-5" }, [
                        _vm._v(
                          " Changes Requested by " +
                            _vm._s(
                              _vm.tripRequest.approval?.audit?.[0]?.username
                            ) +
                            " "
                        ),
                      ]),
                      _c("span", [
                        _vm._v(
                          "Please make the required changes and resubmit this trip request."
                        ),
                      ]),
                      _c("br"),
                      _c("v-label", [
                        _vm._v(
                          _vm._s(_vm.tripRequest.approval?.audit?.[0]?.comment)
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm.showApprovalSlider
                ? _c(
                    "v-slide-group",
                    _vm._l(
                      _vm.tripRequest.approval.requiredApprovalLevels,
                      function (level, i) {
                        return _c("v-slide-item", { key: i }, [
                          _c(
                            "div",
                            [
                              _c(
                                "v-tooltip",
                                {
                                  key: i,
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    key: i,
                                                    class: `subtitle-2 level ${
                                                      _vm.levelColors[i] ==
                                                      "blue"
                                                        ? "font-weight-bold"
                                                        : ""
                                                    }`,
                                                    style: `color: ${
                                                      _vm.tripRequest.approval
                                                        .approved
                                                        ? "success"
                                                        : _vm.levelColors[i]
                                                    }`,
                                                  },
                                                  "div",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.tripRequest
                                                            .approval
                                                            .approved ||
                                                          _vm.levelColors[i] ==
                                                            "green",
                                                        expression:
                                                          "tripRequest.approval.approved || levelColors[i] == 'green'",
                                                      },
                                                    ],
                                                    staticClass: "mr-1",
                                                    attrs: { color: "green" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-checkbox-marked-circle "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " + _vm._s(level.name) + " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.levelTooltips[i]),
                                    },
                                  }),
                                ]
                              ),
                              i <
                              _vm.tripRequest.approval.requiredApprovalLevels
                                .length -
                                1
                                ? _c(
                                    "v-icon",
                                    { key: i + 5000, staticClass: "mx-4" },
                                    [_vm._v(" mdi-arrow-right-bold ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        {
          class: _vm.canShowTripBottomMenu ? "container-banner" : "container",
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-card",
            { staticClass: "form" },
            [
              _vm.cardContent == "tripRequest"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _vm.showTripStepper
                        ? _c("trip-request-stepper", {
                            attrs: {
                              tripRequest: _vm.tripRequest,
                              complete: _vm.complete,
                              tripRequestConfig: _vm.tripRequestConfig,
                              filteredCFFs: _vm.filteredCFFs,
                              e1: _vm.e1,
                              isMobile: true,
                              showFunding: _vm.showFunding,
                              showAdditionalInfo: _vm.showAdditionalInfo,
                            },
                            on: {
                              stepClick: function ($event) {
                                return _vm.$emit("stepClick", $event)
                              },
                              save: function ($event) {
                                return _vm.$emit("save", $event)
                              },
                              refreshTripRequest: function ($event) {
                                return _vm.$emit("refreshTripRequest", $event)
                              },
                              tripTypeSelected: function ($event) {
                                return _vm.$emit("tripTypeSelected", $event)
                              },
                              handlePreventSubmit: function ($event) {
                                return _vm.$emit("handlePreventSubmit", $event)
                              },
                              handleCFFInput: function ($event) {
                                return _vm.$emit("handleCFFInput", $event)
                              },
                            },
                          })
                        : _c("trip-request-review", {
                            attrs: {
                              tripRequest: _vm.tripRequest,
                              complete: _vm.complete,
                              tripRequestConfig: _vm.tripRequestConfig,
                              filteredCFFs: _vm.filteredCFFs,
                              e1: _vm.e1,
                              showAdditionalInfo: _vm.showAdditionalInfo,
                              duplicated: _vm.duplicated,
                            },
                            on: {
                              save: function ($event) {
                                return _vm.$emit("save", false, false, true)
                              },
                              refreshTripRequest: function ($event) {
                                return _vm.$emit("refreshTripRequest", $event)
                              },
                              tripTypeSelected: function ($event) {
                                return _vm.$emit("tripTypeSelected", $event)
                              },
                              handlePreventSubmit: function ($event) {
                                return _vm.$emit("handlePreventSubmit", $event)
                              },
                              handleCFFInput: function ($event) {
                                return _vm.$emit("handleCFFInput", $event)
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "assignments"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-assignments", {
                        ref: "assignments",
                        attrs: {
                          assignments: _vm.tripRequest.assignments,
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                        },
                        on: {
                          refreshTripRequest: function ($event) {
                            return _vm.$emit("refreshTripRequest")
                          },
                          refreshTripRequests: _vm.getTripRequests,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "comments"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-comments", {
                        ref: "comments",
                        attrs: {
                          comments: _vm.tripRequest.userComments,
                          tripRequestId: _vm.tripRequest.id,
                        },
                        on: {
                          refreshTripRequest: function ($event) {
                            return _vm.$emit("refreshTripRequest")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "emails"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-emails", {
                        ref: "emails",
                        attrs: {
                          emails: _vm.tripRequest.userEmails,
                          tripRequestId: _vm.tripRequest.id,
                          requesterId: _vm.tripRequest.submittedUser,
                        },
                        on: {
                          refreshTripRequest: function ($event) {
                            return _vm.$emit("refreshTripRequest")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardContent == "audit"
                ? _c(
                    "v-card-text",
                    { staticClass: "card-content" },
                    [
                      _c("trip-request-audit", {
                        ref: "audit",
                        attrs: {
                          auditHistory: _vm.tripRequest.auditHistory,
                          approval: _vm.tripRequest.approval,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-toolbar",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cardContent == "tripRequest",
                      expression: "cardContent == 'tripRequest'",
                    },
                  ],
                  staticClass: "main-card-footer sticky-footer",
                },
                [
                  _vm.tripRequest.status != 1 && !_vm.duplicated
                    ? [
                        _c("div", { staticClass: "d-flex align-center" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "v-stepper__step__step primary white--text",
                            },
                            [_vm._v(_vm._s(_vm.e1))]
                          ),
                          _c(
                            "p",
                            { staticClass: "text-truncate d-inline mb-0" },
                            [_vm._v(_vm._s(_vm.stepLabels[_vm.e1 - 1]))]
                          ),
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.e1 > 1,
                                expression: "e1 > 1",
                              },
                            ],
                            staticClass: "nav-buttons ma-2",
                            attrs: {
                              color: "primary",
                              disabled: _vm.preventSubmit,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("prev")
                              },
                            },
                          },
                          [_vm._v(" Prev ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showNext,
                                expression: "showNext",
                              },
                            ],
                            staticClass: "nav-buttons ma-2",
                            attrs: {
                              color: "primary",
                              loading: _vm.isProcessingNextStep,
                              disabled: _vm.preventSubmit,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("next")
                              },
                            },
                          },
                          [_vm._v(" Next ")]
                        ),
                        _vm.showSubmit
                          ? [
                              _vm.tripRequestConfig.display.sendQuotes &&
                              _vm.tripRequestConfig.display.contractors &&
                              (_vm.tripRequest.vehicleType ==
                                _vm.VEHICLE_TYPES.APPROVED_CHARTER ||
                                _vm.tripRequest.vehicleType ==
                                  _vm.VEHICLE_TYPES.CONTRACTOR) &&
                              !_vm.tripRequest.additionalTransportationId
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.saving,
                                        disabled:
                                          _vm.preventSubmit || _vm.saving,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("save", false, true)
                                        },
                                      },
                                    },
                                    [_vm._v(" Save as Draft ")]
                                  )
                                : _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "nav-buttons ma-2",
                                                        attrs: {
                                                          color: "primary",
                                                          disabled:
                                                            _vm.preventSubmit ||
                                                            _vm.submitting,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "submit"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" Submit ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2611885220
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tripRequestConfig.messages.final
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                              _c("v-spacer"),
                              _vm.tripRequest.status == 1 &&
                              (_vm.tripRequest.permissions.canEdit ||
                                _vm.tripRequest.permissions.canEditSiteAdmin ||
                                _vm.tripRequest.permissions.canEditApprover ||
                                _vm.tripRequest.permissions
                                  .canEditPreApproval ||
                                _vm.tripRequest.permissions
                                  .canEditMidApproval ||
                                _vm.tripRequest.permissions.canEditApproved)
                                ? _c(
                                    "v-btn",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.cardContent == "tripRequest",
                                          expression:
                                            "cardContent == 'tripRequest'",
                                        },
                                      ],
                                      attrs: {
                                        color: "green white--text mt-2 mr-2",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("save", true)
                                        },
                                      },
                                    },
                                    [_vm._v("Save ")]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    : [
                        _c(
                          "v-menu",
                          {
                            staticClass: "bottom-nav",
                            attrs: { "offset-y": "", top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _vm.tripRequest.status != 0 &&
                                    !_vm.duplicated
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.cardContent ==
                                                      "tripRequest",
                                                    expression:
                                                      "cardContent == 'tripRequest'",
                                                  },
                                                ],
                                                staticClass: "mt-2 mx-2",
                                                attrs: { color: "primary" },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" Actions ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("denyRequest")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "error" } },
                                          [_vm._v("mdi-close-circle")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("Deny Request"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("requestChanges")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "warning" } },
                                          [_vm._v("mdi-pencil-circle")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("Request Changes"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.approving ||
                                        !_vm.canApproveCurrentTrip,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("approve")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "success" } },
                                          [_vm._v("mdi-check-circle")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        " Approve " +
                                          _vm._s(
                                            _vm.tripRequest.approval &&
                                              _vm.tripRequest.approval
                                                .awaitingApproval
                                              ? _vm.tripRequest.approval
                                                  .awaitingApproval.name
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("requestQuote")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-file-document-outline")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("Request For Quote"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.showTripEstimator
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit(
                                              "openTripEstimator"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("mdi-map-marker")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Trip Estimator"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showDownloadPermissionSlip
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit("permissionSlip")
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("mdi-download")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Permission Slip"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("v-divider"),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.canReschedule ||
                                        _vm.cannotReschedule,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("cancel")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-cancel")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("Cancel Trip"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      disabled:
                                        !_vm.canReschedule ||
                                        _vm.cannotReschedule,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("reschedule")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-calendar-edit")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v("Reschedule"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.tripRequest.status == 1 &&
                                _vm.canCreateDuplicate
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit("createDuplicate")
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "primary" } },
                                              [_vm._v("mdi-content-duplicate")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-list-item-title", [
                                          _vm._v("Create Duplicate"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _vm.tripRequest.status == 1 &&
                        (_vm.tripRequest.permissions.canEdit ||
                          _vm.tripRequest.permissions.canEditSiteAdmin ||
                          _vm.tripRequest.permissions.canEditApprover ||
                          _vm.tripRequest.permissions.canEditPreApproval ||
                          _vm.tripRequest.permissions.canEditMidApproval ||
                          _vm.tripRequest.permissions.canEditApproved)
                          ? _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.cardContent == "tripRequest",
                                    expression: "cardContent == 'tripRequest'",
                                  },
                                ],
                                attrs: { color: "green white--text mt-2 mr-2" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("save", true)
                                  },
                                },
                              },
                              [_vm._v("Save ")]
                            )
                          : _vm._e(),
                      ],
                ],
                2
              ),
              _vm.canShowTripBottomMenu
                ? _c(
                    "v-bottom-navigation",
                    {
                      staticClass: "bottom-nav",
                      attrs: { scroll: "disable" },
                      model: {
                        value: _vm.bottomNav,
                        callback: function ($$v) {
                          _vm.bottomNav = $$v
                        },
                        expression: "bottomNav",
                      },
                    },
                    _vm._l(_vm.sideNav, function (item, i) {
                      return _c(
                        "v-btn",
                        {
                          key: i,
                          attrs: { value: item.name },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("setCard", item.name)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }