var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      class: [
        _vm.isMobile ? "form-wrapper-mobile" : "form-wrapper",
        !_vm.tripRequest.submittedUser ||
        (_vm.tripRequest.status == -1 && _vm.tripRequest.submittedUser)
          ? "with-banner"
          : "",
      ],
    },
    [
      !_vm.isMobile
        ? [
            _c(
              "v-col",
              {
                staticClass: "overflow-y-auto fill-height",
                attrs: { cols: "12", md: "2" },
              },
              [
                _c(
                  "v-stepper",
                  {
                    staticStyle: { "box-shadow": "unset" },
                    attrs: {
                      vertical: "",
                      "non-linear": _vm.tripRequest.status == 1,
                    },
                    model: {
                      value: _vm.e1,
                      callback: function ($$v) {
                        _vm.e1 = $$v
                      },
                      expression: "e1",
                    },
                  },
                  [
                    _vm.tripRequest.status != 0 && _vm.tripRequest.status != -1
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-subtitle-1 font-weight-bold text-center",
                          },
                          [
                            _vm._v(
                              " Trip Request #" +
                                _vm._s(_vm.tripRequest.id) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-stepper-step",
                      {
                        class: _vm.tripRequest.status != 0 ? "step-header" : "",
                        attrs: { complete: _vm.complete.general, step: "1" },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(1)
                          },
                        },
                      },
                      [_vm._v(" General ")]
                    ),
                    _c("v-stepper-content", { attrs: { step: "1" } }),
                    _c(
                      "v-stepper-step",
                      {
                        class: _vm.tripRequest.status != 0 ? "step-header" : "",
                        attrs: {
                          complete: _vm.complete.leaveReturn,
                          step: "2",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(2)
                          },
                        },
                      },
                      [_vm._v(" Leave / Return ")]
                    ),
                    _c("v-stepper-content", { attrs: { step: "2" } }),
                    _c(
                      "v-stepper-step",
                      {
                        class: _vm.tripRequest.status != 0 ? "step-header" : "",
                        attrs: {
                          complete: _vm.complete.destination,
                          step: "3",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(3)
                          },
                        },
                      },
                      [_vm._v(" Destination(s) ")]
                    ),
                    _c("v-stepper-content", { attrs: { step: "3" } }),
                    _c(
                      "v-stepper-step",
                      {
                        class: _vm.tripRequest.status != 0 ? "step-header" : "",
                        attrs: { complete: _vm.complete.attendees, step: "4" },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(4)
                          },
                        },
                      },
                      [_vm._v(" Attendees ")]
                    ),
                    _c("v-stepper-content", { attrs: { step: "4" } }),
                    _c(
                      "v-stepper-step",
                      {
                        class: _vm.tripRequest.status != 0 ? "step-header" : "",
                        attrs: {
                          complete: _vm.complete.transportation,
                          step: "5",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(5)
                          },
                        },
                      },
                      [_vm._v(" Transportation ")]
                    ),
                    _c("v-stepper-content", { attrs: { step: "5" } }),
                    _vm.showFunding
                      ? _c(
                          "v-stepper-step",
                          {
                            class:
                              _vm.tripRequest.status != 0 ? "step-header" : "",
                            attrs: {
                              complete: _vm.complete.fundingSource,
                              step: _vm.fStep,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.stepClick(6)
                              },
                            },
                          },
                          [_vm._v(" Funding ")]
                        )
                      : _vm._e(),
                    _vm.showFunding
                      ? _c("v-stepper-content", { attrs: { step: _vm.fStep } })
                      : _vm._e(),
                    _vm.showAdditionalInfo
                      ? _c(
                          "v-stepper-step",
                          {
                            class:
                              _vm.tripRequest.status != 0 ? "step-header" : "",
                            attrs: {
                              complete: _vm.complete.additional,
                              step: _vm.aiStep,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.stepClick(_vm.aiStep)
                              },
                            },
                          },
                          [_vm._v(" Additional Info ")]
                        )
                      : _vm._e(),
                    _vm.showAdditionalInfo
                      ? _c("v-stepper-content", { attrs: { step: _vm.aiStep } })
                      : _vm._e(),
                    _c(
                      "v-stepper-step",
                      {
                        class: _vm.tripRequest.status != 0 ? "step-header" : "",
                        attrs: {
                          complete: _vm.complete.supporting,
                          step: _vm.sdStep,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.stepClick(_vm.sdStep)
                          },
                        },
                      },
                      [_vm._v(" Supporting Documents ")]
                    ),
                    _c("v-stepper-content", { attrs: { step: _vm.sdStep } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-divider", { attrs: { vertical: "" } }),
          ]
        : _vm._e(),
      _c(
        "v-col",
        {
          class: _vm.isMobile ? "form-content-mobile" : "form-content",
          attrs: { cols: "12", md: _vm.isMobile ? 12 : 10, id: "form-content" },
        },
        [
          _c(
            "v-stepper",
            {
              staticStyle: { "box-shadow": "unset" },
              model: {
                value: _vm.e1,
                callback: function ($$v) {
                  _vm.e1 = $$v
                },
                expression: "e1",
              },
            },
            [
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c("step-general", {
                        ref: "stepGeneral",
                        attrs: {
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                          customFormFields: _vm.filteredCFFs[0],
                          step: _vm.e1,
                          readonly: _vm.readonly,
                        },
                        on: {
                          tripTypeSelected: function ($event) {
                            return _vm.tripTypeSelected($event)
                          },
                          handleCFFInput: function ($event) {
                            return _vm.handleCFFInput($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "2" } },
                    [
                      _c("step-leave-return", {
                        attrs: {
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                          step: _vm.e1,
                          complete: _vm.complete.leaveReturn,
                          customFormFields: _vm.filteredCFFs[1],
                          readonly: _vm.readonly,
                        },
                        on: {
                          handleCFFInput: function ($event) {
                            return _vm.handleCFFInput($event)
                          },
                          preventSubmit: function ($event) {
                            return _vm.handlePreventSubmit($event)
                          },
                          save: function ($event) {
                            return _vm.$emit("save")
                          },
                          refresh: _vm.refreshTripRequest,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "3" } },
                    [
                      _c("step-destination", {
                        attrs: {
                          customFormFields: _vm.filteredCFFs[2],
                          readonly: _vm.readonly,
                          step: _vm.e1,
                          tripRequest: _vm.tripRequest,
                        },
                        on: {
                          handleCFFInput: function ($event) {
                            return _vm.handleCFFInput($event)
                          },
                          preventSubmit: function ($event) {
                            return _vm.handlePreventSubmit($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "4" } },
                    [
                      _c("step-attendees", {
                        attrs: {
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                          step: _vm.e1,
                          complete: _vm.complete.attendees,
                          customFormFields: _vm.filteredCFFs[3],
                          readonly: _vm.readonly,
                        },
                        on: {
                          handleCFFInput: function ($event) {
                            return _vm.handleCFFInput($event)
                          },
                          preventSubmit: function ($event) {
                            return _vm.handlePreventSubmit($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "5" } },
                    [
                      _c("step-transportation", {
                        attrs: {
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                          customFormFields: _vm.filteredCFFs[4],
                          step: _vm.e1,
                          readonly: _vm.readonly,
                        },
                        on: {
                          handleCFFInput: function ($event) {
                            return _vm.handleCFFInput($event)
                          },
                          preventSubmit: function ($event) {
                            return _vm.handlePreventSubmit($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showFunding
                    ? _c(
                        "v-stepper-content",
                        { attrs: { step: _vm.fStep } },
                        [
                          _c("step-funding-sources", {
                            attrs: {
                              tripRequest: _vm.tripRequest,
                              tripRequestConfig: _vm.tripRequestConfig,
                              customFormFields: _vm.filteredCFFs[5],
                              step: _vm.e1,
                              readonly: _vm.readonly,
                            },
                            on: {
                              handleCFFInput: function ($event) {
                                return _vm.handleCFFInput($event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showAdditionalInfo
                    ? _c(
                        "v-stepper-content",
                        { attrs: { step: _vm.aiStep } },
                        [
                          _c("step-additional-info", {
                            attrs: {
                              tripRequest: _vm.tripRequest,
                              tripRequestConfig: _vm.tripRequestConfig,
                              customFormFields: _vm.filteredCFFs[6],
                              readonly: _vm.readonly,
                              step: _vm.e1,
                              complete: _vm.complete.additional,
                            },
                            on: {
                              handleCFFInput: function ($event) {
                                return _vm.handleCFFInput($event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-stepper-content",
                    { attrs: { step: _vm.sdStep } },
                    [
                      _c("step-supporting-documents", {
                        attrs: {
                          tripRequest: _vm.tripRequest,
                          step: _vm.e1,
                          customFormFields: _vm.filteredCFFs[7],
                          readonly: _vm.readonly,
                        },
                        on: {
                          handleCFFInput: function ($event) {
                            return _vm.handleCFFInput($event)
                          },
                          refresh: _vm.refreshTripRequest,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }