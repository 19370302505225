<template>
  <v-row
    :class="[
      isMobile ? 'form-wrapper-mobile' : 'form-wrapper',
      !tripRequest.submittedUser || (tripRequest.status == -1 && tripRequest.submittedUser) ? 'with-banner' : '',
    ]"
  >
    <template v-if="!isMobile">
      <v-col cols="12" md="2" class="overflow-y-auto fill-height">
        <v-stepper v-model="e1" vertical :non-linear="tripRequest.status == 1" style="box-shadow: unset">
          <div
            v-if="tripRequest.status != 0 && tripRequest.status != -1"
            class="text-subtitle-1 font-weight-bold text-center"
          >
            Trip Request #{{ tripRequest.id }}
          </div>
          <v-stepper-step
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(1)"
            :complete="complete.general"
            step="1"
          >
            General
          </v-stepper-step>
          <v-stepper-content step="1"></v-stepper-content>

          <v-stepper-step
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(2)"
            :complete="complete.leaveReturn"
            step="2"
          >
            Leave / Return
          </v-stepper-step>
          <v-stepper-content step="2"></v-stepper-content>

          <v-stepper-step
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(3)"
            :complete="complete.destination"
            step="3"
          >
            Destination(s)
          </v-stepper-step>
          <v-stepper-content step="3"></v-stepper-content>

          <v-stepper-step
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(4)"
            :complete="complete.attendees"
            step="4"
          >
            Attendees
          </v-stepper-step>
          <v-stepper-content step="4"></v-stepper-content>

          <v-stepper-step
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(5)"
            :complete="complete.transportation"
            step="5"
          >
            Transportation
          </v-stepper-step>
          <v-stepper-content step="5"></v-stepper-content>

          <v-stepper-step
            v-if="showFunding"
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(6)"
            :complete="complete.fundingSource"
            :step="fStep"
          >
            Funding
          </v-stepper-step>
          <v-stepper-content :step="fStep" v-if="showFunding"></v-stepper-content>

          <v-stepper-step
            v-if="showAdditionalInfo"
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(aiStep)"
            :complete="complete.additional"
            :step="aiStep"
          >
            Additional Info
          </v-stepper-step>
          <v-stepper-content :step="aiStep" v-if="showAdditionalInfo"></v-stepper-content>

          <v-stepper-step
            :class="tripRequest.status != 0 ? 'step-header' : ''"
            @click="stepClick(sdStep)"
            :complete="complete.supporting"
            :step="sdStep"
          >
            Supporting Documents
          </v-stepper-step>
          <v-stepper-content :step="sdStep"></v-stepper-content>
        </v-stepper>
      </v-col>
      <v-divider vertical></v-divider>
    </template>
    <v-col
      cols="12"
      :md="isMobile ? 12 : 10"
      :class="isMobile ? 'form-content-mobile' : 'form-content'"
      id="form-content"
    >
      <v-stepper v-model="e1" style="box-shadow: unset">
        <v-stepper-items>
          <v-stepper-content step="1">
            <step-general
              ref="stepGeneral"
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :customFormFields="filteredCFFs[0]"
              :step="e1"
              :readonly="readonly"
              @tripTypeSelected="tripTypeSelected($event)"
              @handleCFFInput="handleCFFInput($event)"
            ></step-general>
          </v-stepper-content>

          <v-stepper-content step="2">
            <step-leave-return
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :step="e1"
              :complete="complete.leaveReturn"
              :customFormFields="filteredCFFs[1]"
              :readonly="readonly"
              @handleCFFInput="handleCFFInput($event)"
              @preventSubmit="handlePreventSubmit($event)"
              @save="$emit('save')"
              @refresh="refreshTripRequest"
            ></step-leave-return>
          </v-stepper-content>

          <v-stepper-content step="3">
            <step-destination
              :customFormFields="filteredCFFs[2]"
              :readonly="readonly"
              :step="e1"
              :tripRequest="tripRequest"
              @handleCFFInput="handleCFFInput($event)"
              @preventSubmit="handlePreventSubmit($event)"
            ></step-destination>
          </v-stepper-content>

          <v-stepper-content step="4">
            <step-attendees
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :step="e1"
              :complete="complete.attendees"
              :customFormFields="filteredCFFs[3]"
              :readonly="readonly"
              @handleCFFInput="handleCFFInput($event)"
              @preventSubmit="handlePreventSubmit($event)"
            ></step-attendees>
          </v-stepper-content>

          <v-stepper-content step="5">
            <step-transportation
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :customFormFields="filteredCFFs[4]"
              :step="e1"
              :readonly="readonly"
              @handleCFFInput="handleCFFInput($event)"
              @preventSubmit="handlePreventSubmit($event)"
            ></step-transportation>
          </v-stepper-content>

          <v-stepper-content :step="fStep" v-if="showFunding">
            <step-funding-sources
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :customFormFields="filteredCFFs[5]"
              :step="e1"
              :readonly="readonly"
              @handleCFFInput="handleCFFInput($event)"
            ></step-funding-sources>
          </v-stepper-content>

          <v-stepper-content :step="aiStep" v-if="showAdditionalInfo">
            <step-additional-info
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :customFormFields="filteredCFFs[6]"
              :readonly="readonly"
              :step="e1"
              :complete="complete.additional"
              @handleCFFInput="handleCFFInput($event)"
            ></step-additional-info>
          </v-stepper-content>

          <v-stepper-content :step="sdStep">
            <step-supporting-documents
              :tripRequest="tripRequest"
              :step="e1"
              :customFormFields="filteredCFFs[7]"
              :readonly="readonly"
              @handleCFFInput="handleCFFInput($event)"
              @refresh="refreshTripRequest"
            ></step-supporting-documents>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import StepGeneral from './Step.General.vue';
import StepLeaveReturn from './Step.LeaveReturn.vue';
import StepDestination from './Step.Destination.vue';
import StepAttendees from './Step.Attendees.vue';
import StepTransportation from './Step.Transportation.vue';
import StepFundingSources from './Step.FundingSources.vue';
import StepAdditionalInfo from './Step.AdditionalInfo.vue';
import StepSupportingDocuments from './Step.SupportingDocuments.vue';

export default {
  name: 'TripRequestStepper',
  inject: ['eventHub'],
  components: {
    StepGeneral,
    StepLeaveReturn,
    StepDestination,
    StepAttendees,
    StepTransportation,
    StepFundingSources,
    StepAdditionalInfo,
    StepSupportingDocuments,
  },
  props: {
    tripRequest: Object,
    complete: Object,
    tripRequestConfig: Object,
    filteredCFFs: Object,
    e1: Number,
    isMobile: Boolean,
    showFunding: Boolean,
    showAdditionalInfo: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('user', ['me']),
    fStep() {
      if (!this.showFunding) return 0;
      return 6;
    },
    aiStep() {
      if (!this.showAdditionalInfo) return 0;
      if (!this.showFunding) return 6;
      else return 7;
    },
    sdStep() {
      if (!this.showAdditionalInfo && !this.showFunding) return 6;
      if ((!this.showFunding && this.showAdditionalInfo) || (!this.showAdditionalInfo && this.showFunding)) return 7;
      else return 8;
    },
    readonly() {
      if (this.tripRequest.status < -1) return true;
      else
        return (
          this.tripRequest.status == 1 &&
          !this.tripRequest.permissions.canEdit &&
          !this.tripRequest.permissions.canEditPreApproval &&
          !this.tripRequest.permissions.canEditMidApproval
        );
    },
  },
  created() {},
  methods: {
    stepClick(step) {
      this.$emit('stepClick', step);
    },
    refreshTripRequest() {
      this.$emit('refreshTripRequest', this.tripRequest.id);
    },
    tripTypeSelected(tripTypeId) {
      this.$emit('tripTypeSelected', tripTypeId);
    },
    handlePreventSubmit(option) {
      this.$emit('handlePreventSubmit', option);
    },
    handleCFFInput(input) {
      this.$emit('handleCFFInput', input);
    },
  },
  watch: {
    e1() {
      const form = document.getElementById('form-content');
      form.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.form-wrapper {
  height: calc(100dvh - 84px - 94px - 96px);
  height: calc(100vh - 84px - 94px - 96px);
}
.form-wrapper.with-banner {
  height: calc(100vh - 84px - 94px - 160px);
}
.form-wrapper-mobile {
  height: calc(100dvh - 56px - 56px - 28px);
  height: calc(100vh - 56px - 56px);
}
.form-content {
  overflow-y: auto;
  height: 100%;
}
.form-content-mobile {
  overflow-y: auto;
  max-height: 100%;
}
.step-header :hover {
  cursor: pointer;
}
</style>
