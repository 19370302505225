var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px", "no-click-animation": "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-alert",
                            { attrs: { outlined: "", type: "info", text: "" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tripRequestConfig.messages.acceptance
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "align-center" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "9" } },
                        [
                          _c("v-label", [
                            _vm._v(
                              "I have read and understand the information above"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center justify-center" },
                          [
                            _c("span", [_vm._v("No")]),
                            _c("v-switch", {
                              staticClass: "px-2",
                              attrs: {
                                "true-value": 1,
                                "false-value": 0,
                                inset: "",
                                "data-cy": "trip-acceptance",
                              },
                              model: {
                                value: _vm.tripRequest.userAccepted,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tripRequest,
                                    "userAccepted",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "tripRequest.userAccepted",
                              },
                            }),
                            _c("span", [_vm._v("Yes")]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    text: "",
                    loading: _vm.loading,
                    disabled: !_vm.tripRequest.userAccepted || _vm.loading,
                    "data-cy": "trip-submit-btn",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submit()
                    },
                  },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }