var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    [
      _c(
        "v-btn",
        {
          staticClass: "nav-button text-capitalize",
          attrs: { text: "" },
          on: { click: _vm.goBack },
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-arrow-left")]),
          _vm._v(" Back "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }