<template>
  <div class="request-detail-mobile">
    <v-banner v-if="showBanner">
      <v-alert
        outlined
        class="amber--text text--darken-1 mb-0"
        icon="mdi-alert"
        text
        prominent
        dense
        v-if="showBannerAlert"
      >
        <h6 class="text-h6 d-inline-block mr-5">
          Changes Requested by {{ tripRequest.approval?.audit?.[0]?.username }}
        </h6>

        <span>Please make the required changes and resubmit this trip request.</span><br />

        <v-label>{{ tripRequest.approval?.audit?.[0]?.comment }}</v-label>
      </v-alert>

      <v-slide-group v-else-if="showApprovalSlider">
        <v-slide-item v-for="(level, i) of tripRequest.approval.requiredApprovalLevels" :key="i">
          <div>
            <v-tooltip bottom :key="i">
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="`subtitle-2 level ${levelColors[i] == 'blue' ? 'font-weight-bold' : ''}`"
                  :style="`color: ${tripRequest.approval.approved ? 'success' : levelColors[i]}`"
                  :key="i"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    class="mr-1"
                    color="green"
                    v-show="tripRequest.approval.approved || levelColors[i] == 'green'"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  {{ level.name }}
                </div>
              </template>

              <span v-html="levelTooltips[i]"></span>
            </v-tooltip>

            <v-icon v-if="i < tripRequest.approval.requiredApprovalLevels.length - 1" :key="i + 5000" class="mx-4">
              mdi-arrow-right-bold
            </v-icon>
          </div>
        </v-slide-item>
      </v-slide-group>
      <!-- <div
          class="level-container"
          v-else-if="
            tripRequest.approval &&
            tripRequest.approval.requiredApprovalLevels &&
            tripRequest.approval.requiredApprovalLevels.length
          "
        >
          <template v-for="(level, i) of tripRequest.approval.requiredApprovalLevels">
            <v-tooltip bottom :key="i">
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="`subtitle-2 level ${levelColors[i] == 'blue' ? 'font-weight-bold' : ''}`"
                  :style="`color: ${tripRequest.approval.approved ? 'success' : levelColors[i]}`"
                  :key="i"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    class="mr-1"
                    color="green"
                    v-show="tripRequest.approval.approved || levelColors[i] == 'green'"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  {{ level.name }}
                </div>
              </template>
              <span v-html="levelTooltips[i]"></span>
            </v-tooltip>

            <v-icon v-if="i < tripRequest.approval.requiredApprovalLevels.length - 1" :key="i + 5000">
              mdi-arrow-right-bold
            </v-icon>
          </template>
        </div> -->
    </v-banner>

    <v-container fluid :class="canShowTripBottomMenu ? 'container-banner' : 'container'">
      <v-card class="form">
        <v-card-text v-if="cardContent == 'tripRequest'" class="card-content">
          <trip-request-stepper
            v-if="showTripStepper"
            :tripRequest="tripRequest"
            :complete="complete"
            :tripRequestConfig="tripRequestConfig"
            :filteredCFFs="filteredCFFs"
            :e1="e1"
            :isMobile="true"
            :showFunding="showFunding"
            :showAdditionalInfo="showAdditionalInfo"
            @stepClick="$emit('stepClick', $event)"
            @save="$emit('save', $event)"
            @refreshTripRequest="$emit('refreshTripRequest', $event)"
            @tripTypeSelected="$emit('tripTypeSelected', $event)"
            @handlePreventSubmit="$emit('handlePreventSubmit', $event)"
            @handleCFFInput="$emit('handleCFFInput', $event)"
          ></trip-request-stepper>

          <trip-request-review
            v-else
            :tripRequest="tripRequest"
            :complete="complete"
            :tripRequestConfig="tripRequestConfig"
            :filteredCFFs="filteredCFFs"
            :e1="e1"
            :showAdditionalInfo="showAdditionalInfo"
            :duplicated="duplicated"
            @save="$emit('save', false, false, true)"
            @refreshTripRequest="$emit('refreshTripRequest', $event)"
            @tripTypeSelected="$emit('tripTypeSelected', $event)"
            @handlePreventSubmit="$emit('handlePreventSubmit', $event)"
            @handleCFFInput="$emit('handleCFFInput', $event)"
          ></trip-request-review>
        </v-card-text>

        <v-card-text v-if="cardContent == 'assignments'" class="card-content">
          <trip-request-assignments
            ref="assignments"
            :assignments="tripRequest.assignments"
            :tripRequest="tripRequest"
            :tripRequestConfig="tripRequestConfig"
            @refreshTripRequest="$emit('refreshTripRequest')"
            @refreshTripRequests="getTripRequests"
          ></trip-request-assignments>
        </v-card-text>

        <v-card-text v-if="cardContent == 'comments'" class="card-content">
          <trip-request-comments
            ref="comments"
            :comments="tripRequest.userComments"
            :tripRequestId="tripRequest.id"
            @refreshTripRequest="$emit('refreshTripRequest')"
          ></trip-request-comments>
        </v-card-text>

        <v-card-text v-if="cardContent == 'emails'" class="card-content">
          <trip-request-emails
            ref="emails"
            :emails="tripRequest.userEmails"
            :tripRequestId="tripRequest.id"
            :requesterId="tripRequest.submittedUser"
            @refreshTripRequest="$emit('refreshTripRequest')"
          ></trip-request-emails>
        </v-card-text>

        <v-card-text v-if="cardContent == 'audit'" class="card-content">
          <trip-request-audit
            ref="audit"
            :auditHistory="tripRequest.auditHistory"
            :approval="tripRequest.approval"
          ></trip-request-audit>
        </v-card-text>

        <v-toolbar v-show="cardContent == 'tripRequest'" class="main-card-footer sticky-footer">
          <template v-if="tripRequest.status != 1 && !duplicated">
            <div class="d-flex align-center">
              <span class="v-stepper__step__step primary white--text">{{ e1 }}</span>
              <p class="text-truncate d-inline mb-0">{{ stepLabels[e1 - 1] }}</p>
            </div>

            <v-spacer></v-spacer>

            <v-btn
              class="nav-buttons ma-2"
              color="primary"
              v-show="e1 > 1"
              @click="$emit('prev')"
              :disabled="preventSubmit"
            >
              Prev
            </v-btn>

            <v-btn
              class="nav-buttons ma-2"
              color="primary"
              v-show="showNext"
              @click="$emit('next')"
              :loading="isProcessingNextStep"
              :disabled="preventSubmit"
            >
              Next
            </v-btn>

            <template v-if="showSubmit">
              <v-btn
                v-if="
                  tripRequestConfig.display.sendQuotes &&
                  tripRequestConfig.display.contractors &&
                  (tripRequest.vehicleType == VEHICLE_TYPES.APPROVED_CHARTER ||
                    tripRequest.vehicleType == VEHICLE_TYPES.CONTRACTOR) &&
                  !tripRequest.additionalTransportationId
                "
                class="ma-2"
                color="primary"
                :loading="saving"
                :disabled="preventSubmit || saving"
                @click="$emit('save', false, true)"
              >
                Save as Draft
              </v-btn>

              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="nav-buttons ma-2"
                    color="primary"
                    @click="$emit('submit')"
                    :disabled="preventSubmit || submitting"
                  >
                    Submit
                  </v-btn>
                </template>
                <span>{{ tripRequestConfig.messages.final }}</span>
              </v-tooltip>

              <!-- <v-list>
                  <v-list-item @click="$emit('denyRequest')">
                    <v-list-item-icon>
                      <v-icon color="error">mdi-close-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Deny Request</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('requestChanges')">
                    <v-list-item-icon>
                      <v-icon color="warning">mdi-pencil-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Request Changes</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('approve')" :disabled="approving">
                    <v-list-item-icon>
                      <v-icon color="success">mdi-check-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Approve
                      {{
                        tripRequest.approval && tripRequest.approval.awaitingApproval
                          ? tripRequest.approval.awaitingApproval.name
                          : ''
                      }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="$emit('requestQuote')">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-file-document-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Request For Quote</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('openTripEstimator')" v-if="showTripEstimator">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Trip Estimator</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('permissionSlip')" v-if="showDownloadPermissionSlip">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Permission Slip</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="$emit('cancel')"
                    :disabled="
                      !canReschedule || cannotReschedule || cannotCancelOrRescheduleDueToInvoiceStatusNotPending
                    "
                  >
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-cancel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Cancel Trip</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="$emit('reschedule')"
                    :disabled="
                      !canReschedule || cannotReschedule || cannotCancelOrRescheduleDueToInvoiceStatusNotPending
                    "
                  >
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-calendar-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Reschedule</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('createDuplicate')" v-if="tripRequest.status == 1 && canCreateDuplicate">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-content-duplicate</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Create Duplicate</v-list-item-title>
                  </v-list-item>
                </v-list> -->

              <v-spacer></v-spacer>
              <v-btn
                v-show="cardContent == 'tripRequest'"
                color="green white--text mt-2 mr-2"
                @click="$emit('save', true)"
                v-if="
                  tripRequest.status == 1 &&
                  (tripRequest.permissions.canEdit ||
                    tripRequest.permissions.canEditSiteAdmin ||
                    tripRequest.permissions.canEditApprover ||
                    tripRequest.permissions.canEditPreApproval ||
                    tripRequest.permissions.canEditMidApproval ||
                    tripRequest.permissions.canEditApproved)
                "
                >Save
              </v-btn>
            </template>
          </template>

          <template v-else>
            <v-menu class="bottom-nav" offset-y top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="cardContent == 'tripRequest'"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-2 mx-2"
                  color="primary"
                  v-if="tripRequest.status != 0 && !duplicated"
                >
                  Actions
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="$emit('denyRequest')">
                  <v-list-item-icon>
                    <v-icon color="error">mdi-close-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Deny Request</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('requestChanges')">
                  <v-list-item-icon>
                    <v-icon color="warning">mdi-pencil-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Request Changes</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('approve')" :disabled="approving || !canApproveCurrentTrip">
                  <v-list-item-icon>
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>
                    Approve
                    {{
                      tripRequest.approval && tripRequest.approval.awaitingApproval
                        ? tripRequest.approval.awaitingApproval.name
                        : ''
                    }}
                  </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="$emit('requestQuote')">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-file-document-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Request For Quote</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('openTripEstimator')" v-if="showTripEstimator">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-map-marker</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Trip Estimator</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('permissionSlip')" v-if="showDownloadPermissionSlip">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-download</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Permission Slip</v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="$emit('cancel')" :disabled="!canReschedule || cannotReschedule">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-cancel</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Cancel Trip</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('reschedule')" :disabled="!canReschedule || cannotReschedule">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-calendar-edit</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Reschedule</v-list-item-title>
                </v-list-item>

                <v-list-item @click="$emit('createDuplicate')" v-if="tripRequest.status == 1 && canCreateDuplicate">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-content-duplicate</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>Create Duplicate</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <v-btn
              v-show="cardContent == 'tripRequest'"
              color="green white--text mt-2 mr-2"
              @click="$emit('save', true)"
              v-if="
                tripRequest.status == 1 &&
                (tripRequest.permissions.canEdit ||
                  tripRequest.permissions.canEditSiteAdmin ||
                  tripRequest.permissions.canEditApprover ||
                  tripRequest.permissions.canEditPreApproval ||
                  tripRequest.permissions.canEditMidApproval ||
                  tripRequest.permissions.canEditApproved)
              "
              >Save
            </v-btn>
          </template>
        </v-toolbar>

        <v-bottom-navigation scroll="disable" class="bottom-nav" v-if="canShowTripBottomMenu" v-model="bottomNav">
          <v-btn v-for="(item, i) in sideNav" :key="i" :value="item.name" @click="$emit('setCard', item.name)">
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_TRIP_REQUESTS } from '@/store/modules/TripRequest/actions';

import TripRequestStepper from './Detail.Stepper.vue';
import TripRequestReview from './Detail.Review.vue';
import TripRequestAssignments from './TripRequestAssignments.vue';
import TripRequestAudit from './Detail.AuditHistory.vue';
import TripRequestComments from './Detail.Comments.vue';
import TripRequestEmails from './Detail.Emails.vue';

import VEHICLE_TYPES from '@/shared/types';
import { TRIP_STATUS } from '@/shared/common';

export default {
  name: 'RequestDetailMobile',
  inject: ['eventHub'],
  components: {
    TripRequestStepper,
    TripRequestReview,
    TripRequestAssignments,
    TripRequestAudit,
    TripRequestComments,
    TripRequestEmails,
  },
  props: {
    approving: Boolean,
    canCreateDuplicate: Boolean,
    cannotReschedule: Boolean,
    canReschedule: Boolean,
    cannotCancelOrRescheduleDueToInvoiceStatusNotPending: { type: Boolean, default: false },
    cardContent: String,
    complete: Object,
    duplicated: Boolean,
    showFunding: Boolean,
    e1: Number,
    filteredCFFs: Object,
    levelColors: Object,
    levelTooltips: Object,
    preventSubmit: Boolean,
    showAdditionalInfo: Boolean,
    showDownloadPermissionSlip: Boolean,
    showNext: Boolean,
    showSubmit: Boolean,
    showTripEstimator: Boolean,
    sideNav: Array,
    submitting: Boolean,
    tripRequest: Object,
    tripRequestConfig: Object,
    isProcessingNextStep: Boolean,
  },
  data() {
    return {
      bottomNav: 'tripRequest',
      TRIP_STATUS,
      VEHICLE_TYPES,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    canShowTripBottomMenu() {
      return (
        this.tripRequest.status != this.TRIP_STATUS.DRAFT &&
        this.tripRequest.status != this.TRIP_STATUS.RESUBMIT &&
        !this.duplicated
      );
    },
    showBannerAlert() {
      return this.tripRequest.status == this.TRIP_STATUS.RESUBMIT && this.tripRequest.submittedUser;
    },
    showApprovalSlider() {
      return this.tripRequest.approval?.requiredApprovalLevels?.length;
    },
    showTripStepper() {
      return (
        this.tripRequest.status != this.TRIP_STATUS.SUBMITTED &&
        this.tripRequest.status >= this.TRIP_STATUS.RESUBMIT &&
        !this.duplicated
      );
    },
    showBanner() {
      return (
        (this.showBannerAlert || this.showApprovalSlider) &&
        ((!this.tripRequest.submittedUser && this.tripRequest.status < this.TRIP_STATUS.SUBMITTED) ||
          (this.tripRequest.status == this.TRIP_STATUS.RESUBMIT && this.tripRequest.submittedUser))
      );
    },
    stepLabels() {
      const labels = ['General', 'Leave / Return', 'Destination(s)', 'Attendees', 'Transportation', 'Funding'];

      if (this.showAdditionalInfo) labels.push('Additional Info');

      labels.push('Supporting Docs');

      return labels;
    },
  },
  methods: {
    ...mapActions('tripRequest', [GET_TRIP_REQUESTS]),
  },
  watch: {},
};
</script>

<style scoped>
.container {
  padding: 0px;
}
.container-banner {
  height: 100%;
}
.form {
  height: 100%;
  width: 100%;
}
.card-content {
  height: calc(100% - 112px);
}
.nav-buttons {
  margin: 0 6px;
}
.level-container {
  display: flex;
  justify-content: space-evenly;
}
.level {
  display: inline-block;
}
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.sticky-footer {
  position: sticky;
  bottom: 0px;
}
.request-detail-mobile {
  height: 100%;
}
</style>
